import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';
import Stories from './Stories';


function About() {
  return <h2>About</h2>;
}

const App = () =>
  <div>
    <Router>
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <a className="navbar-brand" href="#">Navbar</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/guns/" className="nav-link">Guns</Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <main role="main">
        <div className="container-fluid">
          <Route path="/" exact component={About} />
          <Route path="/guns/" component={Stories} />
        </div>
      </main>
    </Router>

  </div >;

export default App;