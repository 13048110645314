import {combineReducers} from 'redux';

import gunsReducer from './guns';
import configurationReducer from './configuration';

const rootReducer = combineReducers({
    guns: gunsReducer,
    configuration: configurationReducer,
});

export default rootReducer;
