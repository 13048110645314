// asynchronous action creator
export const fetchData = () => {
    return (dispatch) => fetch('https://5t9dv9rm2m.execute-api.us-east-1.amazonaws.com/dev/list.json')
        .then(response => response.json())
        .then(json => dispatch({type: "FetchData", data: json}))
        .catch(err => dispatch({type: "ERROR", msg: "Unable to fetch data"}))
};

export const fetchConfiguration = () => {
    return (dispatch) => fetch('https://5t9dv9rm2m.execute-api.us-east-1.amazonaws.com/dev/configuration')
        .then(response => response.json())
        .then(json => dispatch({type: "FetchedConfiguration", data: json}))
        .then(data => fetch('https://5t9dv9rm2m.execute-api.us-east-1.amazonaws.com/dev/list.json'))
        .then(response => response.json())
        .then(json => dispatch({type: "FetchData", data: json}))
        .catch(err => dispatch({type: "ERROR", msg: "Unable to fetch data"}))
};

export const ignoreItems = (urls) => {
    const body = convertStringsToUrlObjects(urls);

    return (dispatch) => {
        fetch(
            'https://5t9dv9rm2m.execute-api.us-east-1.amazonaws.com/dev/ignore',
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
            .then(json => dispatch({type: "RemoveItem", data: body}))
    }
};

export const favouriteItems = (urls) => {
    const body = convertStringsToUrlObjects(urls);

    return (dispatch) => {
        fetch(
            'https://5t9dv9rm2m.execute-api.us-east-1.amazonaws.com/dev/favourite',
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
            .then(json => dispatch({type: "FavouriteItem", data: body}))
    }
};

function convertStringsToUrlObjects(urls) {
    return urls.map(l => ({"url": l}));
}