const INITIAL_STATE = {};

function configurationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "FetchedConfiguration":
            return action.data;
        default:
            return state;
    }
}

export default configurationReducer;
