import React from 'react';
import Story from './Story';
import Button from './Button';

const SubItems = ({ categoryName, items, ignoreItems, favouriteItems }) => {
  const objItems = (items || []).map(story =>
    <Story
      key={story.url}
      story={story}
      ignoreItems={ignoreItems}
      favouriteItems={favouriteItems}
    />
  );
  const allIds = items.map(i => i.url);
  const temparray = chunkArray(objItems, 2);

  return (
    <div>
      <h3>Category name: <var>{categoryName}</var>
        <span className="float-sm-right">
          <Button onClick={() => ignoreItems(allIds)} className="btn btn-danger" children="Ignore group" />
        </span>
      </h3>
      {(temparray || [])
        .map(
          (chunk, index) =>
            <div key={index} className="card-group">{chunk}</div>)
      }
    </div>
  );
};

function chunkArray(myArray, chunk_size) {
  let arrayLength = myArray.length;
  let tempArray = [];

  for (let index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
}

export default SubItems;