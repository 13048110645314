export const groupGuns = (data, configuration) => {
    if (data === undefined || configuration === undefined) {
        return {};
    }

    const result = {};
    const newConf = convertConfiguration(configuration);

    var rest = data;
    var [favourites, rest] = partitionArray(rest, (item) => item.favourite);

    if (favourites.length > 0) {
        result["favourites"] = {};
        result["favourites"]["favs"] = favourites;
    }

    for (let confItem of newConf) {
        const re = confItem["re"];
        var [groupped, rest] = partitionArray(rest, (item) => item.name.toLowerCase().match(re));

        if (groupped.length > 0) {
            if (confItem["cat"] === "ignoreMe") {
                continue;
            }

            if (!result.hasOwnProperty(confItem["cat"])) {
                result[confItem["cat"]] = {};
            }

            result[confItem["cat"]][re.source] = groupped;
        }
    }

    return result
};

const partitionArray = (arr, cond) => {
    const [small, large] =
        arr.reduce((result, element) => {
            result[cond(element) ? 0 : 1].push(element);
            return result;
        }, [[], []]);
    return [small, large];
};

const convertIt = (configuration, apiKeyName, categoryName) => {
    const result = [];

    if (!configuration.hasOwnProperty(apiKeyName)) {
        return result
    }
    for (let regularExpr of configuration[apiKeyName]) {
        result.push({"re": new RegExp(regularExpr), "cat": categoryName})
    }

    return result
};

const convertConfiguration = (configuration) => {
    var result = [];
    result = result.concat(convertIt(configuration, "ignore-text", "ignoreMe"));
    result = result.concat(convertIt(configuration, "high-priority", "interesting"));
    result = result.concat(convertIt(configuration, "low-priority", "lowPriority"));
    result.push({"re": new RegExp(".*"), "cat": "others"});

    return result
};
