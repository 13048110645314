import React from 'react';
import Button from './Button';

const Story = ({story, ignoreItems, favouriteItems}) => {
    const {
        name,
        source,
        created,
        imageLinks,
        url
    } = story;

    let favouriteIt = null;
    if (favouriteItems) {
        favouriteIt = <Button onClick={() => favouriteItems([url])} className="btn btn-success float-sm-right" children="Favourite it"/>;
    }

    return (
        <div className="card border border-dark">
            <h5 className="card-header"><a href={url}>{name}</a></h5>
            <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                    Last modification: {time(created)}
                    <span className="float-sm-right">Source: {source}</span>
                </h6>
                <div className="card-text">
                    <div className="row">
                        {(imageLinks || []).map((imageLink, index) =>
                            <div className="col-lg-2 col-md-3 col-4" key={index}>
                                <div className="thumbnail"><a href={imageLink}><img alt=""
                                                                                    className="img-fluid img-thumbnail"
                                                                                    src={imageLink}/></a></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <Button onClick={() => ignoreItems([url])} className="btn btn-danger" children="Ignore it"/>
                {favouriteIt}
            </div>
        </div>
    );
};

function time(s) {
    return new Date(s * 1e3).toLocaleDateString()
}

export default Story;