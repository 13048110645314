import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchConfiguration, ignoreItems, favouriteItems} from '../actions';
import SubItems from './SubItems';
import {groupGuns} from '../selectors/index'

const Stories = ({guns, fetchDataAndConfigAction, ignoreItemsAction, favouriteItemsAction}) => {
    useEffect(() => {
        fetchDataAndConfigAction()
    }, [fetchDataAndConfigAction]);

    let favouriteItems = generateCategory(guns.favourites, ignoreItemsAction, null, "Favourite items");
    let interestingItems = generateCategory(guns.interesting, ignoreItemsAction, favouriteItemsAction, "Interesting finds links");
    let lowPriorityItems = generateCategory(guns.lowPriority, ignoreItemsAction, favouriteItemsAction, "Low priority finds links");
    let othersItems = generateCategory(guns.others, ignoreItemsAction, favouriteItemsAction, "Other findings");

    return (<div>
        {favouriteItems}
        {interestingItems}
        {othersItems}
        {lowPriorityItems}
    </div>)
};

const mapStateToProps = state => ({
    guns: groupGuns(state.guns, state.configuration),
});

const mapDispatchToProps = dispatch => ({
    fetchDataAndConfigAction: () => dispatch(fetchConfiguration()),
    ignoreItemsAction: id => dispatch(ignoreItems(id)),
    favouriteItemsAction: id => dispatch(favouriteItems(id))
});

function generateCategory(items, ignoreItemsAction, favouriteItemsAction, h2Text) {
    let interestingItems = [];
    for (let key in (items || {})) {
        interestingItems.push(
            <SubItems
                key={key}
                categoryName={key}
                items={items[key]}
                ignoreItems={ignoreItemsAction}
                favouriteItems={favouriteItemsAction}
            />)
    }
    if (interestingItems.length > 0) {
        interestingItems.unshift(<h2 key={h2Text}>{h2Text}</h2>);
    }
    return interestingItems;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stories);