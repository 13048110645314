const INITIAL_STATE = [];

function gunsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "FetchData":
            return action.data;
        case "RemoveItem":
            return handleRemoveItem(state, action);
        case "FavouriteItem":
            return handleFavouriteItem(state, action);
        default:
            return state;
    }
}

function handleRemoveItem(state, action) {
    const blacklistedURLs = new Set(action.data.map(x => x.url));
    return state.filter(item => !blacklistedURLs.has(item.url));
}

function handleFavouriteItem(state, action) {
    const favouritedURLs = new Set(action.data.map(x => x.url));

    return state.map(item => {
        if (favouritedURLs.has(item.url)) {
            item["favourite"] = true;
        }
        return item;
    });
}

export default gunsReducer;